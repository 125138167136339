import React from 'react'

const ZunnoFaq = () => {
    return (
        <div className="zunno_faq py-[70px]">
            <div className="container">
                <div className=' mb-5'>
                    <h2 className="md:text-[30px] text-[20px] font-[700] text-center">Frequently Asked Questions</h2>
                </div>

                {/* <div className='flex justify-end mb-3 cursor-pointer'>
                    <span className='flex items-center gap-2 font-semibold text-[#271A62]' onClick={handleExpandAll}>{!expandAll ? <> Expand all <MdOutlineUnfoldMore /> </> : <>Collapse all <MdUnfoldLess /> </>}</span>
                </div> */}

                {/* <div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className='row gy-3'>
                        <div className='col-lg-6'>
                            <div className='row gy-3'>
                                <div className='col-12'>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed sm:!text-lg" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                                What is the Role of Web Hosting?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse">
                                            <div className="accordion-body visible">
                                                Web hosting provides server space for your website to be accessible online. Choose reliable hosting services to ensure site availability and speed.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12'>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className={`accordion-button collapsed sm:!text-lg`} type="button" data-bs-toggle="collapse" data-bs-target="#webFive" aria-expanded='false' aria-controls="webFive">
                                                What is Website Maintenance?
                                            </button>
                                        </h2>
                                        <div id="webFive" className={`accordion-collapse collapse`} data-bs-parent="#webFaq">
                                            <div className="accordion-body visible">
                                                Website maintenance involves ongoing updates, security checks, and content management to keep your site functioning optimally and securely.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12'>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className={`accordion-button collapsed sm:!text-lg`} type="button" data-bs-toggle="collapse" data-bs-target="#webSix" aria-expanded='false' aria-controls="webSix">
                                                What's the Importance of UX/UI Design?
                                            </button>
                                        </h2>
                                        <div id="webSix" className={`accordion-collapse collapse`} data-bs-parent="#webFaq">
                                            <div className="accordion-body visible">
                                                UX (User Experience) and UI (User Interface) design is essential because an intuitive, visually appealing, and user-friendly website can engage and retain more visitors.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='row gy-3'>
                                <div className='col-12'>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className={`accordion-button collapsed sm:!text-lg`} type="button" data-bs-toggle="collapse" data-bs-target="#webOne" aria-expanded='false' aria-controls="webOne">
                                                What is Website Development?
                                            </button>
                                        </h2>
                                        <div id="webOne" className={`accordion-collapse collapse`} data-bs-parent="#webFaq">
                                            <div className="accordion-body visible">
                                                Website development is the process of building and maintaining websites. It involves designing, coding, and ensuring functionality to create an online presence.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12'>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className={`accordion-button collapsed sm:!text-lg`} type="button" data-bs-toggle="collapse" data-bs-target="#webTwo" aria-expanded='false' aria-controls="webTwo">
                                                Why is Responsive Design Important?
                                            </button>
                                        </h2>
                                        <div id="webTwo" className={`accordion-collapse collapse`} data-bs-parent="#webFaq">
                                            <div className="accordion-body visible">
                                                Responsive design ensures websites adapt to various screen sizes, providing optimal user experiences on mobile devices, tablets, and desktops.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12'>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className={`accordion-button collapsed sm:!text-lg`} type="button" data-bs-toggle="collapse" data-bs-target="#webThree" aria-expanded='false' aria-controls="webThree">
                                                How Do I Ensure Website Security?
                                            </button>
                                        </h2>
                                        <div id="webThree" className={`accordion-collapse collapse`} data-bs-parent="#webFaq">
                                            <div className="accordion-body visible">
                                                Website security involves measures to protect against cyber threats and data breaches. Use HTTPS, strong passwords, regular updates, and security plugins.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div class="accordion accordion-flush border p-4 rounded-lg" id="accordionFlushExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed sm:!text-lg" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                What is the Role of Web Hosting?
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse">
                            <div className="accordion-body visible">
                                Web hosting provides server space for your website to be accessible online. Choose reliable hosting services to ensure site availability and speed.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className={`accordion-button collapsed sm:!text-lg`} type="button" data-bs-toggle="collapse" data-bs-target="#webFive" aria-expanded='false' aria-controls="webFive">
                                What is Website Maintenance?
                            </button>
                        </h2>
                        <div id="webFive" className={`accordion-collapse collapse`} data-bs-parent="#webFaq">
                            <div className="accordion-body visible">
                                Website maintenance involves ongoing updates, security checks, and content management to keep your site functioning optimally and securely.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className={`accordion-button collapsed sm:!text-lg`} type="button" data-bs-toggle="collapse" data-bs-target="#webSix" aria-expanded='false' aria-controls="webSix">
                                What's the Importance of UX/UI Design?
                            </button>
                        </h2>
                        <div id="webSix" className={`accordion-collapse collapse`} data-bs-parent="#webFaq">
                            <div className="accordion-body visible">
                                UX (User Experience) and UI (User Interface) design is essential because an intuitive, visually appealing, and user-friendly website can engage and retain more visitors.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className={`accordion-button collapsed sm:!text-lg`} type="button" data-bs-toggle="collapse" data-bs-target="#webOne" aria-expanded='false' aria-controls="webOne">
                                What is Website Development?
                            </button>
                        </h2>
                        <div id="webOne" className={`accordion-collapse collapse`} data-bs-parent="#webFaq">
                            <div className="accordion-body visible">
                                Website development is the process of building and maintaining websites. It involves designing, coding, and ensuring functionality to create an online presence.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className={`accordion-button collapsed sm:!text-lg`} type="button" data-bs-toggle="collapse" data-bs-target="#webTwo" aria-expanded='false' aria-controls="webTwo">
                                Why is Responsive Design Important?
                            </button>
                        </h2>
                        <div id="webTwo" className={`accordion-collapse collapse`} data-bs-parent="#webFaq">
                            <div className="accordion-body visible">
                                Responsive design ensures websites adapt to various screen sizes, providing optimal user experiences on mobile devices, tablets, and desktops.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className={`accordion-button collapsed sm:!text-lg`} type="button" data-bs-toggle="collapse" data-bs-target="#webThree" aria-expanded='false' aria-controls="webThree">
                                How Do I Ensure Website Security?
                            </button>
                        </h2>
                        <div id="webThree" className={`accordion-collapse collapse`} data-bs-parent="#webFaq">
                            <div className="accordion-body visible">
                                Website security involves measures to protect against cyber threats and data breaches. Use HTTPS, strong passwords, regular updates, and security plugins.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ZunnoFaq