import React from 'react'

const ZunnoAi = () => {
    return (
        <section>
            {/* <div className='bg-black text-white py-[70px]'>
                <h1 className='text-center md:mb-[150px] mb-[100px] xl:text-[60px] lg:text-[50px] md:text-[40px] text-[30px] font-[600]'>How to generate a images using<br /><span className='texts-gradient'>Zunno AI?</span></h1>
                <div className='w-[90%] max-w-[1280px] mx-auto'>
                    <div className='row gx-3 gy-5'>
                        <div className='col-lg-3 col-md-6'>
                            <div className='md:h-full border-[1px] relative rounded-lg border-[#222] bg-[#171717] py-[50px] px-[40px]'>
                                <div className='lg:text-[150px] text-[100px] leading-[130px] mb-4 font-[700] absolute top-[-75px] left-0 texts-gradient'>1</div>
                                <div className='me-3 mt-5'>
                                    <h3 className='text-[24px] mb-[16px]'>A scalable,  reliable platform</h3>
                                    <span class="text-[#d8d8d8] text-sm">Scale your traffic, content, and site performance to match your business — without worrying about reliability.</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6'>
                            <div className='md:h-full border-[1px] md:!mt-0 sm:!mt-[70px] mt-[50px] relative rounded-lg border-[#222] bg-[#171717] py-[50px] px-[40px]'>
                                <div className='lg:text-[150px] text-[100px] leading-[130px] mb-4 font-[700] absolute top-[-75px] left-3 texts-gradient'>2</div>
                                <div className='me-3 mt-5'>
                                    <h3 className='text-[24px] mb-[16px]'>Advanced collaboration</h3>
                                    <span class="text-[#d8d8d8] text-sm">Build and launch sites quickly — and safely — with powerful features designed to help large teams collaborate.</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6'>
                            <div className='md:h-full border-[1px] lg:!mt-0 sm:!mt-[70px] mt-[50px] relative rounded-lg border-[#222] bg-[#171717] py-[50px] px-[40px]'>
                                <div className='lg:text-[150px] text-[100px] leading-[130px] mb-4 font-[700] absolute top-[-75px] left-3 texts-gradient'>3</div>
                                <div className='me-3 mt-5'>
                                    <h3 className='text-[24px] mb-[16px]'>Dedicated, tailored support</h3>
                                    <span class="text-[#d8d8d8] text-sm">From implementation support to in-the-moment troubleshooting, we’re here to offer personalized help.</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6'>
                            <div className='md:h-full border-[1px] lg:!mt-0 sm:!mt-[70px] mt-[50px] border-[#222] relative rounded-lg bg-[#171717] py-[50px] px-[40px]'>
                                <div className='lg:text-[150px] text-[100px] leading-[130px] mb-4 font-[700] absolute top-[-75px] left-3 texts-gradient'>4</div>
                                <div className='me-3 mt-5'>
                                    <h3 className='text-[24px] mb-[16px]'>Security and compliance</h3>
                                    <span class="text-[#d8d8d8] text-sm">Launch with peace of mind thanks to Webflow’s robust security and compliance features and reliable hosting infrastructure.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='container'>
                <div className='py-[50px] back-gradient rounded-[25px] md:px-[50px] px-[30px]'>
                    <h1 className='text-center xl:text-[60px] lg:mb-[60px] mb-[40px] lg:text-[50px] md:text-[40px] text-[30px] font-[600]'>Why Zunno AI?</h1>
                    <div className='row gy-5'>
                        {
                            Array.from({ length: 6 }).map((_, index) => {
                                return (
                                    <div className='col-md-6' key={index}>
                                        <div className='flex items-start gap-3'>
                                            <img src="/assets/images/zunno/icon.svg" alt="icon" />
                                            <div className='text-black/60 text-lg font-medium'>
                                                <p className='mb-1'>Can be integrated to your platform:</p>
                                                <p className='mb-0'>branded or white label</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ZunnoAi

