import React from 'react'
import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';

const ZunnoClient = () => {

    const options = {
        loop: true,
        margin: 30,
        nav: false,
        dots: false,
        autoplay: true,
        slideTransition: 'linear',
        autoplayTimeout: 2000,
        smartSpeed: 2000,
        autoplayHoverPause: false,
        responsive: {
            0: {
                items: 2.5
            },
            500: {
                items: 4
            },
            800:{
                items:6
            },
            1200: {
                items: 8
            }
        }
    };

    const logo = [
        { id: 17, logo: '/assets/images/home/client_logo/21.webp' },
        { id: 28, logo: '/assets/images/home/client_logo/32.png' },
        { id: 1, logo: '/assets/images/home/client_logo/1.png' },
        { id: 2, logo: '/assets/images/home/client_logo/2.png' },
        { id: 14, logo: '/assets/images/home/client_logo/18.png' },
        { id: 36, logo: '/assets/images/home/client_logo/40.png' },
        { id: 37, logo: '/assets/images/home/client_logo/41.png' },
        { id: 12, logo: '/assets/images/home/client_logo/16.png' },
        { id: 7, logo: '/assets/images/home/client_logo/44.png' },
        { id: 35, logo: '/assets/images/home/client_logo/39.png' },
        { id: 15, logo: '/assets/images/home/client_logo/19.png' },
        { id: 5, logo: '/assets/images/home/client_logo/7.png' },
        { id: 6, logo: '/assets/images/home/client_logo/8.png' },
        { id: 8, logo: '/assets/images/home/client_logo/10.png' },
        { id: 9, logo: '/assets/images/home/client_logo/13.png' },
        { id: 10, logo: '/assets/images/home/client_logo/14.png' },
        { id: 38, logo: '/assets/images/home/client_logo/42.png' },
        { id: 11, logo: '/assets/images/home/client_logo/15.png' },
        { id: 13, logo: '/assets/images/home/client_logo/17.png' },
        { id: 4, logo: '/assets/images/home/client_logo/6.png' },
        { id: 16, logo: '/assets/images/home/client_logo/20.png' },
        { id: 18, logo: '/assets/images/home/client_logo/22.webp' },
        { id: 22, logo: '/assets/images/home/client_logo/26.svg' },
        { id: 19, logo: '/assets/images/home/client_logo/23.webp' },
        { id: 20, logo: '/assets/images/home/client_logo/24.png' },
        { id: 21, logo: '/assets/images/home/client_logo/25.svg' },
        { id: 23, logo: '/assets/images/home/client_logo/27.png' },
        { id: 24, logo: '/assets/images/home/client_logo/43.png' },
        { id: 25, logo: '/assets/images/home/client_logo/29.png' },
        { id: 26, logo: '/assets/images/home/client_logo/30.png' },
        { id: 29, logo: '/assets/images/home/client_logo/33.png' },
        { id: 30, logo: '/assets/images/home/client_logo/34.png' },
        { id: 31, logo: '/assets/images/home/client_logo/35.png' },
        { id: 32, logo: '/assets/images/home/client_logo/36.png' },
        { id: 33, logo: '/assets/images/home/client_logo/37.png' },
        { id: 34, logo: '/assets/images/home/client_logo/38.png' },
    ]

    return (
        <div className='erp_platfrom pb-[30px] border-b'>
            <div className="">
                {/* <h1 className="md:text-[24x] text-[20px] text-center mb-5 font-[600]">Trusted by MSMEs Enterprises</h1> */}
                <OwlCarousel className="slide-track owl-theme owl-carousel" {...options}>
                    {
                        logo.map((res) => {
                            return (
                                <>
                                    <div className="item" key={res.id}>
                                        <img src={res.logo} className='!w-[120px]' alt="images not found" />
                                    </div>
                                </>
                            )
                        })
                    }
                </OwlCarousel>
            </div>
        </div>
    )
}

export default ZunnoClient