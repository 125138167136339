import React from 'react'
import { FaPlay } from "react-icons/fa6";

const ZunnoIdea = () => {

    return (
        <section>
            {/* <h1 className='text-center mb-4 xl:text-[60px] lg:text-[50px] md:text-[40px] text-[30px] font-[600]'>Turn any content to <br /><span className='texts-gradient'>images, instantly</span></h1> */}
            <div className='pt-5'>
                <div className='container'>
                    <div className='row gx-lg-5 gy-4 items-center'>
                        <div className='col-lg-7 col-md-6 order-md-1 order-2'>
                            <div className='ps-lg-5'>
                                <h1 className='mb-3'>Social Media</h1>
                                <p className='md:text-lg'>We’re always developing and improving our generative AI tools. Explore the latest news and announcements</p>
                                <button className='text-white zunno_btn px-[24px] py-[10px] font-[500] rounded-lg bg-theme-color'>Explore Social</button>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-6 order-md-2 order-1'>
                            <div className='relative'>
                                <img src='/assets/images/zunno/social.png' alt='social' className='rounded-[15px] mx-auto' />
                                <img src='/assets/images/zunno/play.svg' alt='play' className='absolute bottom-0 right-0'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='py-5'>
                <div className='container'>
                    <div className='row gx-lg-5 gy-4 items-center'>
                        <div className='col-lg-5 col-md-6'>
                            <img src='/assets/images/zunno/product.png' alt='product' className='rounded-[15px]' />
                        </div>
                        <div className='col-lg-7 col-md-6'>
                            <div className='ps-lg-5'>
                                <h1 className='mb-3'>Product Photo</h1>
                                <p className='md:text-lg'>We’re always developing and improving our generative AI tools. Explore the latest news and announcements</p>
                                <button className='text-white zunno_btn px-[24px] py-[10px] font-[500] rounded-lg bg-theme-color'>Explore Product Photo</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ZunnoIdea