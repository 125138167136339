import React, { useState } from 'react'
import { MdOutlineUnfoldMore } from "react-icons/md";
import { MdUnfoldLess } from "react-icons/md";

const WebFaq = () => {

    const [expandAll, setExpandAll] = useState(false);

    const handleExpandAll = () => {
        setExpandAll(!expandAll);
    };

    return (
        <div className="partner_faq pt-[50px]">
            <div className="container">
                <div className=' mb-5'>
                    <h2 className="md:text-[30px] text-[20px] font-[700] text-center">Frequently Asked Questions</h2>
                </div>

                <div className='flex justify-end mb-3 cursor-pointer'>
                    <span className='flex items-center gap-2 font-semibold text-[#271A62]' onClick={handleExpandAll}>{!expandAll ? <> Expand all <MdOutlineUnfoldMore /> </> : <>Collapse all <MdUnfoldLess /> </>}</span>
                </div>

                <div className="accordion faqs" id="softwareFaq">
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className={`accordion-button ${expandAll ? '' : 'collapsed'}`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#softwareOne"
                                aria-expanded={expandAll}
                                aria-controls="softwareOne">
                                What is the Role of Web Hosting?
                            </button>
                        </h2>
                        <div id="softwareOne" className={`accordion-collapse collapse ${expandAll ? 'show' : ''}`} data-bs-parent="#softwareFaq">
                            <div className="accordion-body visible">
                                Web hosting provides server space for your website to be accessible online. Choose reliable hosting services to ensure site availability and speed.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className={`accordion-button ${expandAll ? '' : 'collapsed'}`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#softwareTwo"
                                aria-expanded={expandAll}
                                aria-controls="softwareTwo">
                                What is Website Maintenance?
                            </button>
                        </h2>
                        <div id="softwareTwo" className={`accordion-collapse collapse ${expandAll ? 'show' : ''}`} data-bs-parent="#softwareFaq">
                            <div className="accordion-body visible">
                                Website maintenance involves ongoing updates, security checks, and content management to keep your site functioning optimally and securely.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className={`accordion-button ${expandAll ? '' : 'collapsed'}`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#softwareThree"
                                aria-expanded={expandAll}
                                aria-controls="softwareThree">
                                What's the Importance of UX/UI Design?
                            </button>
                        </h2>
                        <div id="softwareThree" className={`accordion-collapse collapse ${expandAll ? 'show' : ''}`} data-bs-parent="#softwareFaq">
                            <div className="accordion-body visible">
                                UX (User Experience) and UI (User Interface) design is essential because an intuitive, visually appealing, and user-friendly website can engage and retain more visitors.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className={`accordion-button ${expandAll ? '' : 'collapsed'}`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#softwareFour"
                                aria-expanded={expandAll}
                                aria-controls="softwareFour">
                                What is Website Development?
                            </button>
                        </h2>
                        <div id="softwareFour" className={`accordion-collapse collapse ${expandAll ? 'show' : ''}`} data-bs-parent="#softwareFaq">
                            <div className="accordion-body visible">
                                Website development is the process of building and maintaining websites. It involves designing, coding, and ensuring functionality to create an online presence.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className={`accordion-button ${expandAll ? '' : 'collapsed'}`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#softwareFive"
                                aria-expanded={expandAll}
                                aria-controls="softwareFive">
                                Why is Responsive Design Important?
                            </button>
                        </h2>
                        <div id="softwareFive" className={`accordion-collapse collapse ${expandAll ? 'show' : ''}`} data-bs-parent="#softwareFaq">
                            <div className="accordion-body visible">
                                Responsive design ensures websites adapt to various screen sizes, providing optimal user experiences on mobile devices, tablets, and desktops.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className={`accordion-button ${expandAll ? '' : 'collapsed'}`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#softwareSix"
                                aria-expanded={expandAll}
                                aria-controls="softwareSix">
                                How Do I Ensure Website Security?
                            </button>
                        </h2>
                        <div id="softwareSix" className={`accordion-collapse collapse ${expandAll ? 'show' : ''}`} data-bs-parent="#softwareFaq">
                            <div className="accordion-body visible">
                                Website security involves measures to protect against cyber threats and data breaches. Use HTTPS, strong passwords, regular updates, and security plugins.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WebFaq