import React, { useEffect } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { homeBlogData } from '../../../redux/actions/Blogs.action';
import { MdArrowOutward } from "react-icons/md";

const options = {
  loop: true,
  margin: 20,
  autoplay: false,
  dots: false,
  items: 2,
  // navText: ['<i class="fa-solid fa-angle-left"></i>', '<i class="fa-solid fa-angle-right"></i>'],
  // nav: true,
  responsive: {
    0: {
      items: 1
    },
    768: {
      items: 2
    },
    1000: {
      items: 3,
    }
  }
};

function ZunnoBlog() {

  const data = [
    {
      id: 1,
      date: 'Alec Whitten | February 7, 2024',
      title: 'How telcos are leveraging emerging technologies to stay ahead',
      image: '/assets/images/zunno/Image.svg',

    },
    {
      id: 2,
      date: 'Alec Whitten | February 7, 2024',
      title: 'How telcos are leveraging emerging technologies to stay ahead',
      image: '/assets/images/zunno/Image1.svg',

    },
    {
      id: 3,
      date: 'Alec Whitten | February 7, 2024',
      title: 'How telcos are leveraging emerging technologies to stay ahead',
      image: '/assets/images/zunno/Image2.svg',

    },
    {
      id: 4,
      date: 'Alec Whitten | February 7, 2024',
      title: 'How telcos are leveraging emerging technologies to stay ahead',
      image: '/assets/images/zunno/Image3.svg',

    },
  ]

  const dispatch = useDispatch()
  const homeData = useSelector((state) => state.blogs)
  // console.log('homeData', homeData);

  useEffect(() => {
    dispatch(homeBlogData())
  }, [dispatch])

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      {/* <div className='md:mb-[40px] testi-div'>
        <div className='py-[40px]'>
          <div className='container'>
            <p className='text-center md:text-[30px] text-[20px] font-[700]'>Zunno Blog</p>
            <div className=''>
              <OwlCarousel className="owl-theme testimonial_slider1 " {...options}>
                <>
                  {
                    data.map((val) => {
                      return (
                        <div className="item m-2" key={val.id}>
                          <div className='box-trends mt-[20px]' style={{ height: '100%' }}>
                            <div className='main-imagediv' style={{ height: '200px' }}>
                              <img src={val.image} className='img-treads' alt='home blogs'></img>
                            </div>
                            <div className='pt-[10px]'>
                              <p className='font-[500] text-[#6941C6] text-sm'>{val.date}</p>
                              <div className='flex items-center mb-2 justify-between'>
                                <p className='text-lg font-semibold mb-0'>Bill Walsh leadership lessons</p>
                                <MdArrowOutward />
                              </div>
                              <p className='text-[#667085]'>{val.title}</p>
                              <div className='px-[10px] py-[2px] bg-[#F9F5FF] text-[#6941C6] text-sm inline-block rounded-pill mt-2'>Leadership</div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </>
              </OwlCarousel>
            </div>
            <div className='text-center mt-3'>
              <a href='#zunno' style={{ background: 'linear-gradient(270deg,#2b47fd,#a067fc 90%)' }} className='text-white px-[24px] py-[10px] font-[500] rounded-lg d-inline-flex justify-center mx-auto items-center'>Explore all blogs</a>
            </div>
          </div>
        </div>
      </div> */}

      <div className='bg-black md:py-[100px] py-[60px] text-white'>
        <div className='container'>
          <div className='row gy-3'>
            <div className='col-md-4'>
              <h1 className='xl:text-[60px] lg:text-[50px] md:text-[40px] text-[30px] text-white'>Get Started
                for free</h1>
            </div>
            <div className='col-md-6 offset-md-2'>
              <p className='md:text-xl mb-4'>
                Try zunno for as long as you
                like with our free starter plan. Purchase
                a paid Site plan to publish.
              </p>
              <a href='#zunno' style={{ background: 'linear-gradient(90.4deg, #9F68FD -33.79%, #6457FE 58.26%, #344AFF 141.36%)' }} className='text-white px-[24px] py-[10px] font-[500] rounded-lg d-inline-flex justify-center mx-auto items-center'>Try Zunno</a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ZunnoBlog