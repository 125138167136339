import React from 'react'

const ZunnoBanner = () => {
    return (
        <section>
            <div className='py-[60px]'>
                <div className='container'>
                    <div className='text-center'>
                        <h1 className='xl:text-[75px] lg:text-[50px] md:text-[40px] text-[30px] font-[700]'>Create Photos with <br /><span className='texts-gradient'>text prompts</span></h1>
                        <p className='md:text-lg text-[16px] col-lg-9 mx-auto my-[30px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                        <a href='https://zunno.io/' style={{ background: 'linear-gradient(270deg,#2b47fd,#a067fc 90%)' }} className='text-white zunno_btn px-[24px] py-[10px] font-[500] rounded-lg d-inline-flex justify-center mx-auto items-center'>Try Zunno for free</a>

                        {/* <img src='/assets/images/zunno/banner1.png' className='mt-5' alt='hero-video' /> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ZunnoBanner