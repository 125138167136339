import React, { useState } from 'react'
import MonthData from './MonthData';
import AnnuallyData from './AnnuallyData';

const ZunnoPrice = () => {

    const [isMonthly, setIsMonthly] = useState(true);

    const handleClick = () => {
        setIsMonthly(!isMonthly);
    };

    return (
        <div className='py-[70px]'>
            <div className='container'>
                <h1 className='text-center mb-4 xl:text-[60px] lg:text-[50px] md:text-[40px] text-[30px] font-[600]'>The right plans, <br /><span className='texts-gradient'>for the right price</span></h1>

                <p className='text-center mt-2 font-medium md:text-lg text-[#2C3A9B]'>Pick an account plan that fits your workflow</p>

                <div className='flex items-center justify-center gap-3'>
                    <h3 className='text-[#2C3A9B]'>Monthly</h3>
                    <label className='flex justify-center'>
                        <input type='checkbox' className='d-none switch' onChange={() => { }} checked={!isMonthly} />
                        <div className='flex items-center rounded-pill w-[40px] h-[20px] p-1 relative switch_tab cursor-pointer'>
                            <div onClick={handleClick} className={`bg-white w-[15px] h-[15px] duration-500 rounded-full absolute top-1/2 -translate-y-1/2 ${isMonthly ? 'left-[2px]' : 'left-[22px]'} shadow-[0px_0px_3px_#2C3A9B1A]`}></div>
                        </div>
                    </label>
                    <h3 className='text-[#2C3A9B]'>Annually</h3>
                </div>

                <div className="mt-5">
                    {isMonthly ? <MonthData text='text-4xl' padding='md:p-[40px] p-[20px]' /> : <AnnuallyData text='text-4xl' padding='md:p-[40px] p-[20px]' />}
                </div>

                <div className='text-center mt-5'>
                    <a href='#zunno' style={{ background: 'linear-gradient(90.4deg, #9F68FD -33.79%, #6457FE 58.26%, #344AFF 141.36%)' }} className='font-[500] rounded-full d-inline-flex justify-center mx-auto items-center p-[1px] btn_gradient'>
                        <span className='px-[24px] rounded-full py-[10px] bg-white bg-gradients duration-300'><span className='texts-gradient'>Compare all features</span></span>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ZunnoPrice