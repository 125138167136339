import React, { useState, useEffect } from 'react';
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import GetQuote from '../../../common/GetQuote';
import Routes from './routes'
import Offcanvase from './Offcanvase';

const Header = ({ props , text }) => {
  const [isHovered, setIsHovered] = useState(null);
  const [menu, setMenu] = useState(null);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsHeaderFixed(true);
      } else {
        setIsHeaderFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleHover = (id) => {
    setIsHovered(id);
  };

  const handleLeave = () => {
    setIsHovered(null);
  };

  const handleMenuClick = (id) => {
    setMenu(menu === id ? null : id);
    setIsHovered(id);
  };

  const handleBackButtonClick = () => {
    setMenu(null);
    setIsHovered(null);
  };

  return (
    <>
      <div className='d-lg-block d-none'>
        <div className={`main-header bg-${props.bgColor} shadow-sm ${props.dark}`}>
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <div className='d-flex items-center'>
                <a className="navbar-brand" href="/" aria-current="page">
                  <img src={props.logo} onContextMenu={(e) => e.preventDefault()} width="150" alt="logo" />
                </a>
              </div>
              <div className='flex items-center'>
                <button className="navbar-toggler shadow-none" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className={`navbar-toggler-icon ${props.filter}`}></span>
                </button>
              </div>
              <div className={`collapse navbar-collapse visible head_navbar ${props.mode}`} id="navbarSupportedContent">
                <ul className="navbar-nav lg:items-center me-auto mb-2 mb-lg-0">
                  {Routes.map((item) => (
                    <li key={item.id} className="nav-item relative" onMouseEnter={() => handleHover(item.id)} onMouseLeave={handleLeave}>
                      <a className={`nav-link py-4 text-${props.color} cursor-pointer`} href={item.link} style={{ color: isHovered === item.id ? `${props.btn}` : '' }}>
                        <span className='flex items-center' onClick={() => item.megaMenu && handleMenuClick(item.id)}>
                          {item.title}
                          {item.megaMenu && (
                            (menu === item.id || isHovered === item.id) ?
                              <TiArrowSortedUp className="dropdown-icon" /> :
                              <TiArrowSortedDown className="dropdown-icon" />
                          )}
                        </span>
                      </a>

                      {item.megaMenu && (
                        <ul className={`submenu ${isHovered === item.id ? 'active' : ''} bg-${props.bgColor}`}>
                          {item.megaMenu.map((subItem) => (
                            <li key={subItem.id} className='nav-item'>
                              <a href={subItem.link} style={{ color: isHovered === subItem.id ? `${props.btn} bg-[#E9EBF8]` : '' }} className={`nav-link text-${props.color}`}>{subItem.title}</a>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
                <ul className='flex justify-end items-center'>
                  <li className='d-lg-block d-none'>
                    <a href="/contact-us" className={`bg-[${props.btn}] text-${props.bgColor} px-[24px] py-[10px] font-[500] rounded-lg ms-2 d-inline-flex justify-center mx-auto items-center`}>Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <Offcanvase props={props} />
      {/* <GetQuote id="getQuote" /> */}
    </>
  )
}

export default Header;
