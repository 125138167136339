import React from 'react'
import { Helmet } from 'react-helmet'
import Index from './Components/zunno/Index'

const Zunno = () => {
    return (
        <div>
            <Helmet>
                <title>Zunno: Codersbay Technologies</title>
            </Helmet>
            <Index />
        </div>
    )
}

export default Zunno