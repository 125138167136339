import React from 'react'
import Header from '../header/Header'
import Footer from '../footer/footer'
import ZunnoBanner from './ZunnoBanner'
import ZunnoClient from './ZunnoClient'
import ZunnoPrice from './ZunnoPrice'
import WebFaq from '../web-development/WebFaq';
import ZunnoStarted from './ZunnoStarted'
import ZunnoIdea from './ZunnoIdea'
import ZunnoBlog from './ZunnoBlog'
import ZunnoAi from './ZunnoAi'
import ZunnoFaq from './ZunnoFaq'

const Index = () => {
    return (
        <div>
            <Header props={{ "bgColor": "white", "color": "black", "btn": "#271A62", "btnColor": "white", "logo": "/assets/images/Logo.svg" }} />
            <ZunnoBanner/>
            <ZunnoClient/>
            <ZunnoIdea/>
            <ZunnoAi/>
            <ZunnoPrice/>
            <ZunnoStarted/>
            {/* <WebFaq/> */}
            <ZunnoBlog/>
            <ZunnoFaq/>
            <Footer props={{ "logo": "/assets/images/Logo.svg", "bgColor": "#E6EBF5", "title": "#271A62", "text": "black", "copyright": "#271A62" }} />
        </div>
    )
}

export default Index