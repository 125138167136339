import React, { useEffect, useRef, useState } from 'react';

const ZunnoStarted = () => {
    const zunoSectionRef = useRef(null);
    const [isAtBottom, setIsAtBottom] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            const zunoSectionRect = zunoSectionRef.current.getBoundingClientRect();
            
            if (zunoSectionRect.bottom <= window.innerHeight && currentScrollY > lastScrollY) {
                setIsAtBottom(true);
            } else if (currentScrollY < lastScrollY) {
                setIsAtBottom(false);
            }

            setLastScrollY(currentScrollY);
        };

        let ticking = false;
        const debouncedScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    handleScroll();
                    ticking = false;
                });
                ticking = true;
            }
        };

        window.addEventListener('scroll', debouncedScroll);

        return () => {
            window.removeEventListener('scroll', debouncedScroll);
        };
    }, [lastScrollY]);

    return (
        <div className='bg-black text-white pt-[70px] pb-[50px]'>
            <div className="relative pb-5 overflow-clip">
                <div className='container'>
                    <div className='row gy-4'>
                        <div className='col-md-6'>
                            <img src='/assets/images/zunno/idea5.svg' />
                        </div>
                        <div className='col-md-6'>
                            <img src='/assets/images/zunno/idea6.svg' className='md:mt-[50px]' />
                        </div>
                        <div className='col-md-6'>
                            <img src='/assets/images/zunno/idea7.svg' />
                        </div>
                        <div className='col-md-6'>
                            <img src='/assets/images/zunno/idea8.svg' className='md:mt-[200px] mb-5' />
                        </div>
                    </div>
                </div>
                <div
                    ref={zunoSectionRef}
                    className={`absolute inset-0 flex items-start top-[35%] shadow-[0px_67.11px_134.68px_#000000CC] justify-center transition-transform transition-scale duration-500 
                    ${isAtBottom ? 'scale-[0.9] translate-y-[150px]' : 'scale-100 translate-y-0'}`}>
                    <div className='bg-white sticky top-[30%] text-[#2C3A9B] rounded-lg px-4 py-2 flex items-center gap-3'>
                        <img src='/assets/images/favicon.svg' className='md:w-[70px] w-[40px]' />
                        <p className='xl:text-5xl lg:text-4xl md:text-3xl text-2xl font-bold mb-0'>Made with Zunno</p>
                    </div>
                </div>
                <div className='started_layer overflow-hidden'></div>
            </div>            
        </div>
    );
}

export default ZunnoStarted;
